/* eslint-disable no-console */
import {
    page,
    search,
    messagesHeaderLinkClick,
    // inventory
    inventoryImpressions,
    inventoryProductImpressions,
    inventoryClick,
    inventorySave,
    inventoryCarouselPagination,
    inventoryProductClick,
    inventoryCertifiedTileClick,
    // owner
    ownerImpression,
    ownerImpressions,
    dealerClick,
    ownerLogoClick,
    ownerCall,
    ownerEmailClick,
    ownerEmailCTAClick,
    ownerEmailSent,
    ownerWebsite,
    ownerPhoneNumber,
    ownerLocation,
    ownerText,
    ownerChat,
    ownerContactClick,
    paginationClick,
    sortDropDownClick,
    sitemapFilterClick,
    inventoryScrollImpressions,
    numRecordsDropDownClick,
    filterClick,
    carAlertsClick,
    headerGeneralEventHandler,
    footerLinkClick,
    ownerServiceInquiry,
    searchRadiusClick,
    serviceAppointmentSubmit,
    serviceAppointmentCTAEcomClick,
    serviceAppointmentCTAEcomImpression,
    ownerPriceQuoteClick,
} from 'reaxl-analytics';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { cloneDeep } from 'lodash';
import { pageNames } from '@atc/bonnet-paths';
import {
    userDuck,
    ownersDuck,
    inventoryFiltersDuck,
    dealerPaginationDuck,
    dealerResultsDuck,
    dealerFiltersDuck,
    inventoryResultsDuck,
    inventoryActiveOwnerDuck,
    inventoryMetadataDuck,
    currentPageNameDuck,
    dealerMetadataDuck,
} from '../ducks';
import { kbbBrand, atcBrand } from '../constants';
import { getTabName, getSelectedTabFromState, transformPixallEmailLabel } from '../utilities';

function mapMetadataToSearchData(metadata, dealerId) {
    let filters = null;
    if (dealerId > 0) {
        const searchValue = inventoryFiltersDuck.selectors.getValuesState(metadata.state);
        const { maxPrice, minPrice, mileage, startYear, endYear, ...rest } = searchValue;
        const formatedRest = {};
        const inventoryResultData = inventoryResultsDuck.selectors.getDuckState(metadata.state);
        const stats = _get(inventoryResultData, 'stats', {});
        const minYearStat = _get(stats, 'year.min', '');
        const maxYearStat = _get(stats, 'year.max', '');
        const minPriceStat = _get(stats, 'derivedprice.min', '');
        const maxPriceStat = _get(stats, 'derivedprice.max', '');
        const keyMappings = [
            { old: 'modelCode', new: 'modelCodeList' },
            { old: 'trimCode', new: 'trimCodeList' },
            { old: 'vehicleStyleCode', new: 'vehicleStyleCodes' },
            { old: 'mpgRange', new: 'mpgRanges' },
        ];
        Object.entries(rest).forEach(([key, value]) => {
            const mappedKey = keyMappings.find((mapping) => key.includes(mapping.old))?.new || key;
            formatedRest[mappedKey] = value?.value;
        });
        filters = {
            ...metadata.dataLayer.search?.filters,
            maxPrice: maxPrice?.value || maxPriceStat,
            minPrice: minPrice?.value || minPriceStat,
            mileage: mileage ? `under-${mileage.value}` : mileage,
            startYear: (startYear?.value && +startYear?.value > 0) ? startYear?.value : minYearStat,
            endYear: (endYear?.value && +endYear?.value > 0) ? endYear?.value : maxYearStat,
            ...formatedRest,
        };
    } else {
        const searchValue = dealerFiltersDuck.selectors.getValuesState(metadata.state);
        const { listingType, makeCode, zip } = searchValue;
        filters = {
            ...metadata.dataLayer.search?.filters,
            listingType: listingType?.value,
            makeCode: makeCode?.value,
            zip: zip?.value,
        };
    }

    return {
        ...metadata.dataLayer.search,
        filters,
    };
}

function getAnalyticDDPPageName(metadata) {
    const { detailPageName } = metadata?.dataLayer?.page;
    if (detailPageName && detailPageName.includes('ddp')) {
        const { state } = metadata;
        const tabName = getTabName(state);
        return tabName;
    }
    return detailPageName;
}

function getInventoryId(activeOwner, inventory) {
    let inventoryId;
    if (activeOwner > 0) {
        inventoryId = Object.values(inventory)[0]?.id || 0;
    }
    return inventoryId;
}

const addZipCodeToState = (metadata) => {
    const zipCode = _get(metadata, 'dataIsland.page.location.address', undefined);
    metadata.state = {
        ...metadata.state,
        query: {
            zip: zipCode,
        },
    };
};

const notSendPixall = (activeOwner, inventoryLength, metadata, inventoryId) => {
    if (activeOwner > 0 && inventoryLength > 0) {
        Object.assign(metadata.state.inventory[inventoryId],
            {
                listingType: undefined,
                make: '',
                model: '',
                pricingDetail: { noPriceLabel: undefined, priceValidUntil: undefined, salePrice: undefined },
                stockNumber: undefined,
                styleId: undefined,
                type: undefined,
                year: undefined,
                exteriorColorSimple: undefined,
                vin: undefined,
                priority: undefined,
                specifications: { mileage: { value: undefined } },
                mpgCity: undefined,
                mpgHighway: undefined,
            });
    }
};
export default {
    // Standard page event callback
    page: (metadata, option) => {
        const currentPageName = currentPageNameDuck.selectors.getDuckState(metadata.state);
        const brand = _get(metadata.state, 'brand', atcBrand);
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});

        inventoryActiveOwnerDuck.selectors.getDuckState(metadata.state);
        const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(metadata.state);
        metadata.dataLayer.search = mapMetadataToSearchData(metadata, activeOwner);
        metadata.dataLayer.page.detailPageName = getAnalyticDDPPageName(metadata);

        const metadataFromDuck = currentPageName === pageNames.DEALER_SEARCH
            ? dealerMetadataDuck.selectors.getDuckState(metadata.state)
            : inventoryMetadataDuck.selectors.getDuckState(metadata.state);
        metadata.data.title = _get(metadataFromDuck, 'title', undefined);

        const inventory = _get(metadata, 'state.inventory', {});
        const inventoryLength = Object.keys(inventory)?.length;
        const inventoryId = getInventoryId(activeOwner, inventory);

        delete metadata.state['dealersearch.filters']?.values?.searchRadius;

        notSendPixall(activeOwner, inventoryLength, metadata, inventoryId);
        if (brand === kbbBrand) {
            return page({
                ...metadata,
                data: {
                    ...metadata.data,
                    subCategory: 'unk',
                    interactionType: '2',
                    articleId: activeOwner > 0 ? '154893' : undefined,
                    pageType: 'dealer',
                },
                userDuck,
            }, option);
        }
        return page({
            ...metadata,
            data: {
                ...metadata.data,
                pageType: 'dealer',
            },
            userDuck,
        }, option);
    },
    search: (metadata, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        return search({
            ...metadata,
            cmp: 'fyd_srch',
            userDuck,
        }, option);
    },

    messagesHeaderLinkClick,

    dealerLotCarouselPagination: (metadata, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        return inventoryCarouselPagination({
            ...metadata,
            data: {
                ...metadata.data,
                par: 'dl_v_lstg_1',
                listingType: 'v_dl',
            },
        }, option);
    },

    inventoryImpressions: (metadata, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        if (_get(metadata.state, 'brand', atcBrand) !== kbbBrand) {
            Object.keys(metadata.dataLayer.inventory).map((key) => {
                metadata.dataLayer.inventory[key].owner = undefined;
            });
        }
        return inventoryImpressions({
            ...metadata,
            data: {
                ...metadata.data,
            },
            userDuck,
        }, option);
    },

    inventoryProductImpressions: (metadata, option) => inventoryProductImpressions({
        ...metadata,
    }, option),

    inventoryClick: (metadata, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        return inventoryClick({
            ...metadata,
            userDuck,
        }, option);
    },

    inventorySave: (metadata, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        return inventorySave({
            ...metadata,
            dataLayer: {
                ...metadata.dataLayer,
                page: {
                    ...metadata.dataLayer.page,
                    pageName: metadata.dataLayer.page.detailPageName,
                },
                user: {
                    ...metadata.dataLayer.user,
                    isLoggedIn: metadata.dataLayer.user?.isLoggedIn ? metadata.dataLayer.user?.isLoggedIn : '0',
                },
            },
            data: {
                ...metadata.data,
                pixallData: {
                    listingType: metadata.data.listingType,
                },
            },
            userDuck,
        }, option);
    },

    ownerImpression: (metadata, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        return ownerImpression({
            ...metadata,
            userDuck,
        }, option);
    },
    ownerWebsite: (metadata, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        const pageName = _get(metadata.dataIsland, 'page.site.pageName', '');
        const { ownerId } = metadata.data;
        const cloneMetadata = cloneDeep(metadata);

        if (_get(cloneMetadata.state, 'brand', atcBrand) === kbbBrand) {
            Object.assign(cloneMetadata.state, { query: { zip: cloneMetadata.dataIsland.page.BIRF.z } });
            _set(cloneMetadata.state, `owners.${ownerId}.id`, ownerId);
        }
        const isNav = _get(metadata.data, 'rightNav', false);
        return ownerWebsite({
            ...cloneMetadata,
            data: {
                ...metadata.data,
                valueEventCode: isNav ? 'O7C4' : 'OAAD',
                customerType: _get(metadata.state, 'inventory[Object.keys(metadata.state.inventory)[0]].type', 'dealer'),
                priorityType: _get(metadata.state, 'inventory[Object.keys(metadata.state.inventory)[0]].priority', 'premium'),
            },
            dataLayer: {
                ...metadata.dataLayer,
                page: {
                    ...metadata.dataLayer.page,
                    pageName: pageName === pageNames.DEALER_SEARCH ? 'fyd' : 'ddp',
                },
            },
            pixallData: {
                listingCode: ownerId,
                hasListingType: false,
            },
            userDuck,
        }, option);
    },
    ownerText: (metadata, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        return ownerText({
            ...metadata,
            userDuck,
        }, option);
    },

    ownerChat: ({ data, ...metadata }, option) => {
        let inventoryId = '';
        const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(metadata.state);
        if (activeOwner) {
            inventoryId = metadata.state.inventory[Object.keys(metadata.state.inventory)[0]].id?.toString();
        }
        const inventory = _get(metadata, 'state.inventory', {});
        metadata.dataLayer.page.pageType = 'dealer';
        const inventoryLength = Object.keys(inventory)?.length;
        notSendPixall(activeOwner, inventoryLength, metadata, inventoryId);
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        addZipCodeToState(metadata);
        return ownerChat({
            ...metadata,
            data: {
                ...data,
                inventoryId,
                cmp: 'cs_chat',
                pageType: 'dealer',
                hasListingType: false,
                assistChannel: 'Website',
                assistProduct: 'Chat',
                assistSubchannel: 'ActivEngage',
            },
            userDuck,
        }, option);
    },

    ownerPriceQuoteClick: (metadata, option) => ownerPriceQuoteClick({
        ...metadata,
        userDuck,
    }, option),

    serviceAppointmentSubmit: ({ data, ...metadata }, option) => {
        let inventoryId = '';
        const { ownerId } = data;
        const activeOwner = ownerId ?? inventoryActiveOwnerDuck.selectors.getDuckState(metadata.state);
        const owner = _get(metadata.state.owners, activeOwner, {});
        owner.shippingLabel = owner.shippingLabel ?? 'Delivery Available';
        if (activeOwner) {
            const inventory = _get(metadata, 'state.inventory', {});
            inventoryId = getInventoryId(activeOwner, inventory);
        }
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        return serviceAppointmentSubmit({
            ...metadata,
            data: {
                ...data,
                owner,
                inventoryId,
            },
        }, option);
    },

    serviceAppointmentCTAEcomClick: ({ data, ...metadata }, option) => {
        let inventoryId = '';
        const { ownerId } = data;
        const activeOwner = ownerId ?? inventoryActiveOwnerDuck.selectors.getDuckState(metadata.state);
        const owner = _get(metadata.state.owners, activeOwner, {});
        if (activeOwner) {
            const inventory = _get(metadata, 'state.inventory', {});
            inventoryId = getInventoryId(activeOwner, inventory);
        }
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        return serviceAppointmentCTAEcomClick({
            ...metadata,
            data: {
                ...data,
                owner,
                inventoryId,
            },
            dataLayer: {
                ...metadata.dataLayer,
                page: {
                    ...metadata.dataLayer.page,
                },
            },
        }, option);
    },

    serviceAppointmentCTAEcomImpression: ({ data, ...metadata }, option) => {
        let inventoryId = '';
        const { ownerId } = data;
        const activeOwner = ownerId ?? inventoryActiveOwnerDuck.selectors.getDuckState(metadata.state);
        const owner = _get(metadata.state.owners, activeOwner, {});
        if (activeOwner) {
            const inventory = _get(metadata, 'state.inventory', {});
            inventoryId = getInventoryId(activeOwner, inventory);
        }
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        return serviceAppointmentCTAEcomImpression({
            ...metadata,
            data: {
                ...data,
                owner,
                inventoryId,
            },
            dataLayer: {
                ...metadata.dataLayer,
                page: {
                    ...metadata.dataLayer.page,
                },
            },
        }, option);
    },

    ownerPhoneNumber: ({ data, ...metadata }, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        if (_get(metadata.state, 'brand', atcBrand) === kbbBrand) {
            data.par = `${metadata.dataIsland.page.BIRF.pg_id}`;
        }
        data.ownerId = data.ownerId?.toString();
        data.paginatedListingIndex = data.paginatedListingIndex?.toString();
        return ownerPhoneNumber({
            ...metadata,
            data: {
                ...data,
            },
            userDuck,
        }, option);
    },

    adobeClickOnlyWebComponent: ({ data, ...metadata }, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        if (_get(metadata.state, 'brand', atcBrand) === kbbBrand) {
            data.par = `${metadata.dataIsland.page.BIRF.pg_id}`;
        }
        const refinedOption = { ...option, disablePixall: true, disableGTM: true };
        data.ownerId = data.ownerId?.toString();
        data.paginatedListingIndex = data.paginatedListingIndex?.toString();
        return ownerPhoneNumber({
            ...metadata,
            data: {
                ...data,
            },
            userDuck,
        }, refinedOption);
    },

    ownerLocation: ({ data, ...metadata }, option) => {
        const { pageName } = data;
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        if (_get(metadata.state, 'brand', atcBrand) === kbbBrand) {
            Object.assign(metadata.state, { query: { zip: metadata.dataIsland.page.BIRF.z } });
        }
        const ownerId = inventoryActiveOwnerDuck.selectors.getDuckState(metadata.state);
        return ownerLocation({
            ...metadata,
            data: {
                ...data,
                ownerId,
                cmp: _get(metadata.state, 'brand', atcBrand) === kbbBrand ? 'cs_view_map' : undefined,
                hasListingType: false,
            },
            dataLayer: {
                ...metadata.dataLayer,
                page: {
                    ...metadata.dataLayer.page,
                    pageName,
                },
            },
            userDuck,
        }, option);
    },

    dealerLotTileClick: (metadata, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        return ownerLogoClick({
            ...metadata,
            data: {
                ...metadata.data,
                par: 'dl_v_lstg_1',
                listingType: 'v_dl',
                pixallData: {
                    isCarousel: true,
                },
            },
            userDuck,
        }, option);
    },

    ownerImpressions: (metadata, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        return ownerImpressions({
            ...metadata,
            userDuck,
            ownersDuck,
            resultsDuck: dealerResultsDuck,
        }, option);
    },

    // standard inventory click event
    dealerClick: ({ data, ...metadata }, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        if (_get(metadata.state, 'brand', atcBrand) === kbbBrand) {
            metadata.dataIsland.page.BIRF.co_txt_url = 'View Cars';
        }
        return dealerClick({
            ...metadata,
            data: {
                ...data,
                cmp: 'c_lstg_t',
                hasListingType: false,
            },
            userDuck,
        }, option);
    },

    ownerCall: (metadata, option) => {
        addZipCodeToState(metadata);
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        const selectedTab = getSelectedTabFromState(metadata.state);
        const metadataFromDuck = inventoryMetadataDuck.selectors.getDuckState(metadata.state);
        const pageTitle = _get(metadataFromDuck, 'title', undefined);
        return ownerCall({
            ...metadata,
            data: {
                ...metadata.data,
                selectedTab: selectedTab?.name === 'service' ? selectedTab.name : 'dealer',
                pageTitle,
            },
            userDuck,
        }, option);
    },

    ownerEmailSent: ({ data, ...metadata }, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(metadata.state);
        const owner = _get(metadata.state?.owners, activeOwner, {});
        const label = transformPixallEmailLabel(data?.subject);

        return ownerEmailSent({
            ...metadata,
            data: {
                ...data,
                par: 'ddv_lstg',
                hasListingType: false,
                leadLabel: label,
                ...owner.homeServices,
            },
            userDuck,
            dataLayer: {
                ...metadata.dataLayer,
                page: {
                    ...metadata.dataLayer.page,
                    pageName: data.pageName || metadata.dataLayer.page?.pageName,
                },
            },
        }, option);
    },

    ownerEmailClick: (metadata, option) => ownerEmailClick({
        ...metadata,
        userDuck,
    }, option),

    ownerEmailCTAClick: ({ data, ...metadata }, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        let inventoryId = '';
        const brand = _get(metadata.state, 'brand', atcBrand);
        const ownerId = inventoryActiveOwnerDuck.selectors.getDuckState(metadata.state);
        const metadataFromDuck = inventoryMetadataDuck.selectors.getDuckState(metadata.state);
        const pageTitle = _get(metadataFromDuck, 'title', undefined);
        if (brand === kbbBrand) {
            const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(metadata.state);
            if (activeOwner > 0 && metadata.state.inventory.length > 0) {
                inventoryId = metadata.state.inventory[Object.keys(metadata.state.inventory)[0]].id?.toString();
            }
            return ownerEmailCTAClick({
                ...metadata,
                data: {
                    ...data,
                    disableFeature: true,
                    par: 'ddv_lstg',
                    inventoryId,
                    ownerId,
                    title: pageTitle,
                },
                userDuck,
                dataLayer: {
                    ...metadata.dataLayer,
                    page: {
                        ...metadata.dataLayer.page,
                        pageName: 'ddp',
                    },
                },
            }, option);
        }
        return ownerEmailCTAClick({
            ...metadata,
            data: {
                ...data,
                par: 'ddv_lstg',
                ownerId,
                inventoryId,
                title: pageTitle,
            },
            userDuck,
            dataLayer: {
                ...metadata.dataLayer,
                page: {
                    ...metadata.dataLayer.page,
                    pageName: 'ddp',
                },
            },
        }, option);
    },

    inventoryProductClick: (metadata, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        let vendor;
        const accelerateLogo = _get(metadata.event, 'target.alt', '');
        if (accelerateLogo === 'Accelerate Logo') return;
        if (_get(metadata.state, 'brand', atcBrand) === kbbBrand) {
            if (metadata.data.listing.productTiles[0].tileType.includes('CARFAX')) {
                vendor = 'CarFax';
            }
            if (metadata.data.listing.productTiles[0].tileType.includes('AutoCheck')) {
                vendor = 'AutoCheck';
            }
        }
        return inventoryProductClick({
            ...metadata,
            data: {
                ...metadata.data,
                vendor: _get(metadata.state, 'brand', atcBrand) === kbbBrand ? vendor : '',
            },
            userDuck,
            filterDuck: inventoryFiltersDuck,
        }, option);
    },

    listingProductLinkClick: (metadata, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        return inventoryProductClick({
            ...metadata,
            data: {
                ...metadata.data,
                cmp: 'ec_pa_txt',
            },
            userDuck,
            filterDuck: inventoryFiltersDuck,
        }, option);
    },

    inventoryCertifiedTileClick: (metadata, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        return inventoryCertifiedTileClick({
            ...metadata,
            userDuck,
        }, option);
    },

    paginationClick: (metadata, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        const pageName = _get(metadata.dataIsland, 'page.site.pageName', '');

        return paginationClick({
            ...metadata,
            dataLayer: {
                ...metadata.dataLayer,
                page: {
                    ...metadata.dataLayer.page,
                    pageName: pageName === pageNames.DEALER_SEARCH ? 'fyd' : 'fyc',
                },
            },
            filterDuck: dealerFiltersDuck,
            paginationDuck: dealerPaginationDuck,
            resultsDuck: dealerResultsDuck,
            userDuck,
        }, option);
    },

    sortDropDownClick: (metadata, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        const pageName = _get(metadata.dataIsland, 'page.site.pageName', '');

        return sortDropDownClick({
            ...metadata,
            dataLayer: {
                ...metadata.dataLayer,
                page: {
                    ...metadata.dataLayer.page,
                    pageName: pageName === pageNames.DEALER_SEARCH ? 'fyd' : 'fyc',
                },
            },
            filterDuck: inventoryFiltersDuck,
            paginationDuck: dealerPaginationDuck,
            resultsDuck: dealerResultsDuck,
            userDuck,
        }, option);
    },

    sitemapFilterClick: (metadata, option) => sitemapFilterClick({
        ...metadata,
    }, option),

    inventoryScrollImpressions: (metadata, option) => {
        const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(metadata.state);
        if (activeOwner === null) {
            return inventoryScrollImpressions({
                ...metadata,
            }, option);
        }
        return {};
    },

    numRecordsDropDownClick: (metadata, option) => numRecordsDropDownClick({
        ...metadata,
        dataLayer: {
            ...metadata.dataLayer,
            page: {
                ...metadata.dataLayer.page,
                pageName: 'fyc',
            },
        },
        filterDuck: dealerFiltersDuck,
        paginationDuck: dealerPaginationDuck,
        resultsDuck: dealerResultsDuck,
    }, option),

    filterClick: (metadata, option) => {
        metadata.dataIsland = _get(metadata.state, 'birf.pageData', {});
        const pageName = _get(metadata.dataIsland, 'page.site.pageName', '');

        return filterClick({
            ...metadata,
            dataLayer: {
                ...metadata.dataLayer,
                page: {
                    ...metadata.dataLayer.page,
                    pageName: pageName === pageNames.DEALER_SEARCH ? 'fyd' : 'fyc',
                },
            },
            filterDuck: metadata.data?.filterDuck,
        }, option);
    },

    carAlertsClick: (metadata, ...arg) => carAlertsClick({
        ...metadata,
    }, ...arg),

    headerGeneralEventHandler: (metadata, option) => headerGeneralEventHandler({
        ...metadata,
        data: {
            ...metadata.data,
        },
    }, option),

    footerLinkClick: (metadata, option) => footerLinkClick({
        ...metadata,
        data: {
            ...metadata.data,
            pagetype: metadata.data.pagetype?.replace('_', '-'),
        },
    }, option),
    ownerServiceInquiry: (metadata, option) => ownerServiceInquiry({
        ...metadata,
        data: {
            ...metadata.data,
        },
        dataLayer: {
            ...metadata.dataLayer,
            inventory: metadata.state.owners,
        },
    }, option),
    searchRadiusClick: (metadata, option) => searchRadiusClick({
        ...metadata,
        dataLayer: {
            ...metadata.dataLayer,
            page: {
                ...metadata.dataLayer.page,
                pageName: 'fyd',
            },
        },
    }, option),

    ownerContactClick: ({ data, ...metadata }, option) => {
        let inventoryId = '';
        const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(metadata.state);
        const owner = (_get(metadata.state?.owners, activeOwner, {}));

        if (activeOwner) {
            inventoryId = metadata.state.inventory[Object.keys(metadata.state.inventory)[0]].id?.toString();
        }
        let pixallData = {};

        if (metadata[0] === 'contactUSCTA') {
            pixallData = {
                label: 'cta::email::contact-us',
                leadType: 'form',
                assistChannel: 'Website',
                assistProduct: 'Chat',
                assistSubchannel: 'ActivEngage',
            };
        } else {
            pixallData = {
                label: 'contact-dealer',
            };
        }

        return ownerContactClick({
            ...metadata,
            data: {
                ...data,
                inventoryId,
                owner,
                hasListingType: false,
                pixallData: {
                    ...pixallData,
                    feature: 'Drive the 5',
                },
            },
        }, option);
    },
};
