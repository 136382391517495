import { allMakeValue, kbbBrand, atcBrand } from '../constants';
import { pageNames } from '@atc/bonnet-paths';
import { getPath } from '.';
import { getInventorySortOption } from '.';
import { sortMakeCodeQuery } from '.';

const deleteMakeQuery = (pageName, query) => {
    if (query.make && pageName === pageNames.DEALER_SEARCH) {
        if (query.make !== allMakeValue) {
            query.seoMakeCode = query.make.toLowerCase().replace(' ', '-');
        }
        delete query.make;
    }
    return query;
};

const deleteSearchRadiusQuery = (query, brand) => {
    const { searchRadius } = query;
    if ((brand === kbbBrand && searchRadius === 25) || (brand === atcBrand && searchRadius === 50)) {
        delete query.searchRadius;
    }
    return query;
};

const deleteSortByQuery = (query, brand) => {
    const { sortBy } = query;
    const sortOption = getInventorySortOption(brand);
    if (sortBy === sortOption) {
        delete query.sortBy;
    }
    return query;
};

export default async function redirectInternal(ctx, dispatch, push, options) {
    const { endYear, numRecords } = ctx.query;

    if (!ctx.req && ctx.data.isNewPage) {
        ctx.query = deleteMakeQuery(ctx.pageName, ctx.query);
        if (endYear === '0') {
            delete ctx.query.endYear;
        }
        if (numRecords === 25) {
            delete ctx.query.numRecords;
        }
        ctx.query = deleteSearchRadiusQuery(ctx.query, ctx.data.brand);
        ctx.query = deleteSortByQuery(ctx.query, ctx.data.brand);
        ctx.query = sortMakeCodeQuery(ctx.query);

        const pathname = await getPath(ctx.pageName, ctx.query, { brand: ctx.data?.brand });
        push(pathname, undefined, { ...options });
    }
}
